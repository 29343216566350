// import firebase from 'firebase'; //DevPackage
import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/storage';

import store from '../store';

const config = {
  apiKey: 'AIzaSyCqKWnkFFgvAMDaU5j-Vqh7hfAMaVZhvIY',
  authDomain: 'future-laureates-academy.firebaseapp.com',
  databaseURL: 'https://future-laureates-academy.firebaseio.com',
  projectId: 'future-laureates-academy',
  storageBucket: 'future-laureates-academy.appspot.com',
  messagingSenderId: '115786929108',
};

const fire = firebase.initializeApp(config);
// Sign In User
fire.signIn = async (Email, Pass) => {
  try {
    await firebase.auth().signInWithEmailAndPassword(Email, Pass);
    store.commit('setCurrentUser', firebase.auth().currentUser);
    return true;
  } catch (err) {
    return err;
  }
};
// Sign Out User
fire.signOut = async () => {
  try {
    store.commit('setCurrentUser', null);
    store.commit('setCurrentUserType', null);
    store.commit('setSelectedClass', null);
    this.$store.commit('resetImgUrl');
    await firebase.auth().signOut();
    return true;
  } catch (err) {
    return err;
  }
};

export default fire;
