<template>
  <VApp>
    <VNavigationDrawer
      v-model="drawer"
      persistent
      enable-resize-watcher
      fixed
      width="200"
      app
    >
      <div class="dash-logo">
        <img
          src="./assets/img/logo.png"
          alt="Logo"
          draggable="false"
        >
      </div>
      <div
        v-if="currentUserType !== null"
        id="userInfo"
      >
        <p class="userName">
          {{ currentUserType.name }}
        </p>
        <p class="userRole">
          {{ currentUserType.role }}
        </p>
      </div>
      <VList>
        <VFadeTransition
          mode="out-in"
          group
        >
          <template v-if="!currentUser">
            <VListTile
              v-for="(item, i) in noLoginItems"
              :key="i"
              :to="item.to"
            >
              <VListTileAction>
                <VIcon>{{ item.icon }}</VIcon>
              </VListTileAction>
              <VListTileContent>
                <VListTileTitle v-text="item.title" />
              </VListTileContent>
            </VListTile>
          </template>

          <template v-if="currentUserType !== null && currentUserType.role === 'teacher' ">
            <VListTile
              v-for="(item, i) in teacherItems"
              :key="i"
              :to="item.to"
            >
              <VListTileAction>
                <VIcon>{{ item.icon }}</VIcon>
              </VListTileAction>
              <VListTileContent>
                <VListTileTitle v-text="item.title" />
              </VListTileContent>
            </VListTile>
          </template>
          <template v-if="currentUserType !== null &&currentUserType.role === 'manager'">
            <VListTile
              v-for="(item, i) in managerItems"
              :key="i"
              :to="item.to"
            >
              <VListTileAction>
                <VIcon>{{ item.icon }}</VIcon>
              </VListTileAction>
              <VListTileContent>
                <VListTileTitle v-text="item.title" />
              </VListTileContent>
            </VListTile>
          </template>
          <template v-if="currentUserType !== null &&currentUserType.role === 'admin'">
            <VListTile
              v-for="(item, i) in adminItems"
              :key="i"
              :to="item.to"
            >
              <VListTileAction>
                <VIcon>{{ item.icon }}</VIcon>
              </VListTileAction>
              <VListTileContent>
                <VListTileTitle v-text="item.title" />
              </VListTileContent>
            </VListTile>
          </template>
        </VFadeTransition>
      </VList>
    </VNavigationDrawer>
    <VToolbar
      flat
      app
      color="primary"
      dark
      dense
    >
      <VToolbarSideIcon @click.stop="drawer = !drawer" />
      <VToolbarTitle v-text="title" />
    </VToolbar>
    <VContent>
      <VFadeTransition mode="out-in">
        <RouterView />
      </VFadeTransition>
    </VContent>
  </VApp>
</template>

<script>
export default {
  data() {
    return {
      drawer: null,
      noLoginItems: [
        {
          icon: 'vpn_key',
          title: 'Login',
          to: '/',
        },
        {
          icon: 'home',
          title: 'Home',
          to: '/home',
        },
      ],
      managerItems: [
        {
          icon: 'dashboard',
          title: 'Dashboard',
          to: '/dashboard',
        },
        {
          icon: 'wc',
          title: 'Student',
          to: '/student',
        },
        {
          icon: 'receipt',
          title: 'Receipt',
          to: '/receipt',
        },
        {
          icon: 'exit_to_app',
          title: 'Logout',
          to: '/logout',
        },
      ],
      teacherItems: [
        {
          icon: 'dashboard',
          title: 'Dashboard',
          to: '/dashboard',
        },
        {
          icon: 'access_time',
          title: 'Attendance',
          to: '/attendance',
        },
        {
          icon: 'date_range',
          title: 'Time Table',
          to: '/timetable',
        },
        {
          icon: 'description',
          title: 'Note',
          to: '/note',
        },
        {
          icon: 'assessment',
          title: 'Result',
          to: '/result',
        },
        {
          icon: 'exit_to_app',
          title: 'Logout',
          to: '/logout',
        },
      ],
      adminItems: [
        {
          icon: 'dashboard',
          title: 'Dashboard',
          to: '/dashboard',
        },
        {
          icon: 'wc',
          title: 'Student',
          to: '/student',
        },
        {
          icon: 'receipt',
          title: 'Receipt',
          to: '/receipt',
        },

        {
          icon: 'assessment',
          title: 'Result',
          to: '/result',
        },
        {
          icon: 'sms',
          title: 'Message',
          to: '/message',
        },
        {
          icon: 'description',
          title: 'Note',
          to: '/note',
        },
        {
          icon: 'date_range',
          title: 'Time Table',
          to: '/timetable',
        },
        {
          icon: 'access_time',
          title: 'Attendance',
          to: '/attendance',
        },
        {
          icon: 'settings',
          title: 'Settings',
          to: '/settings',
        },
        {
          icon: 'exit_to_app',
          title: 'Logout',
          to: '/logout',
        },
      ],
      title: 'Future Laureates Academy',
    };
  },
  computed: {
    currentUser() {
      return this.$store.state.currentUser;
    },
    currentUserType() {
      return this.$store.state.currentUserType;
    },
  },
};
</script>

<style lang='stylus'>
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

html, body {
  margin: 0;
  padding: 0;
}

.application {
  background: #eff0f5 !important;
}

.dash-logo {
  text-align: center;
  height: 10rem;
  width: 100%;
  padding: 1rem;
}

.dash-logo img {
  width: 5rem;
}

#userInfo {
  background: #2C69EA;
  color: #ffffff;
  padding: 0.5rem 1.5rem;
  margin: 0 auto;

  p {
    margin: 0;
  }
}

.userName {
  font-size: 1.2rem;
  font-weight: 500;
}

.userRole {
  font-size: 0.9rem;
  font-weight: 500;
  text-transform: uppercase;
}

input[type='number'] {
  -moz-appearance: textfield;
}

input[type='number']::-webkit-inner-spin-button, input[type='number']::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.v-navigation-drawer__border {
  width: 0;
}
</style>
