import Vue from 'vue';
import Router from 'vue-router';
import isAdmin from './conf/accessLevel/admin';
import isManager from './conf/accessLevel/manager';
import isTeacher from './conf/accessLevel/teacher';
import isBasic from './conf/accessLevel/basicAuth';

Vue.use(Router);
const CPNT = name => () => import(`./views/${name}`);
const folderRoute = [
  {
    path: '/student',
    folder: 'Student',
    routes: ['new'],
    wildcard: 'view',
    accessLevel: isManager,
  },
  {
    path: '/receipt',
    folder: 'Receipt',
    routes: ['new'],
    wildcard: 'view',
    accessLevel: isManager,
  },
  {
    path: '/result',
    folder: 'Result',
    routes: ['new'],
    wildcard: 'view',
    accessLevel: isTeacher,
  },
  {
    path: '/message',
    folder: 'Message',
    routes: ['new'],
    wildcard: 'view',
    accessLevel: isAdmin,
  },
  {
    path: '/note',
    folder: 'Note',
    routes: ['new'],
    wildcard: 'view',
    accessLevel: isTeacher,
  },
  {
    path: '/attendance',
    folder: 'Attendance',
    routes: ['new'],
    wildcard: 'view',
    accessLevel: isTeacher,
  },
  {
    path: '/timetable',
    folder: 'TimeTable',
    routes: ['new'],
    wildcard: 'view',
    update: 'update',
    accessLevel: isTeacher,
  },
];
const Routes = [];
// eslint-disable-next-line
folderRoute.forEach(({ path, folder, routes, accessLevel, ...args }) => {
  Routes.push({
    path,
    name: folder,
    component: CPNT(`${folder}/index`),
    beforeEnter: accessLevel,
  });
  routes.forEach((route) => {
    Routes.push({
      path: `${path}/${route}`,
      name: `${folder + route}`,
      component: CPNT(`${folder}/${route}`),
      beforeEnter: accessLevel,
    });
  });

  if (args.wildcard) {
    Routes.push({
      path: `${path}/:id`,
      name: `${folder + args.wildcard}`,
      component: CPNT(`${folder}/${args.wildcard}`),
      beforeEnter: accessLevel,
    });
  }
  if (args.update) {
    Routes.push({
      path: `${path}/update/:id`,
      name: `${folder + args.update}`,
      component: CPNT(`${folder}/${args.update}`),
      beforeEnter: accessLevel,
    });
  }
});

export default new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 };
  },
  routes: [
    // Default Home Login Page
    {
      path: '/',
      name: 'Login',
      component: CPNT('Login'),
    },
    // Redirect To Home Page
    {
      path: '/home',
      name: 'Redirect',
      component: CPNT('Redirect'),
    },
    // Logout Page
    {
      path: '/logout',
      name: 'Logout',
      component: CPNT('Logout'),
      beforeEnter: isBasic,
    },
    // Dashboard Page
    {
      path: '/dashboard',
      name: 'Dashboard',
      component: CPNT('Dashboard'),
      beforeEnter: isBasic,
    },
    // Settings Page
    {
      path: '/settings',
      name: 'Settings',
      component: CPNT('Settings'),
      beforeEnter: isAdmin,
    },
    // Folder Routes
    ...Routes,
    // 404 Redirect's to Dashboard
    {
      path: '*',
      redirect: '/dashboard',
    },
  ],
});
