import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    selectedClass: null,
    currentUser: null,
    currentUserType: null,
    ImgUrl: [],
  },
  mutations: {
    setSelectedClass(state, payload) {
      state.selectedClass = payload;
    },
    setCurrentUser(state, payload) {
      state.currentUser = payload;
    },
    setCurrentUserType(state, payload) {
      state.currentUserType = payload;
    },
    setImgUrl(state, payload) {
      state.ImgUrl.push(payload);
    },
    resetImgUrl(state) {
      state.ImgUrl = [];
    },
  },
  actions: {},
  plugins: [createPersistedState({ key: 'futurelaureatesadmin' })],
});
