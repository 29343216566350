import store from '../../store';

export default (to, from, next) => {
  if (store.state.currentUserType.role === 'manager') {
    next();
  } else if (store.state.currentUserType.role === 'admin') {
    next();
  } else {
    next('/');
  }
};
