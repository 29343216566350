import Vue from 'vue';
import VueAnalytics from 'vue-analytics';
import './plugins/vuetify';
import axios from 'axios';
import App from './App.vue';
import router from './router';
import fire from './conf/firebaseAuth';
import store from './store';

axios.interceptors.request.use(
  async (config) => {
    config.headers.FireAuthToken = await fire.auth().currentUser.getIdToken(true);
    return config;
  },
  error => Promise.reject(error),
);

Vue.config.productionTip = false;

Vue.use(VueAnalytics, {
  id: 'UA-110529539-2',
  router,
});

let app;
const start = () => {
  if (!app) {
    app = new Vue({
      router,
      store,
      render: h => h(App),
    }).$mount('#app');
  }
};

fire.auth().onAuthStateChanged((user) => {
  if (user) {
    user.getIdTokenResult().then((idTokenResult) => {
      store.commit('setCurrentUserType', idTokenResult.claims);
    });
    store.commit('setCurrentUser', user);
  } else {
    store.commit('setCurrentUser', null);
    store.commit('setCurrentUserType', null);
  }
  start();
});
